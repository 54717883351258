.main-stripe {
    background-color: rgb(46, 88, 167);
    padding: 10px 10px 30px 10px;
    text-align: center;
    margin-bottom: 0px;
}

.home-header1 {
    color: white;
    font-size: 40px;
    font-weight: normal;
    padding: 0px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.home-header2 {
    color: white;
    font-weight: normal;
    font-size: 18px;
    width: 95%;
    max-width: 660px;
    text-align: center;
    margin: auto;
    margin-top: 10px;
}

.cards{
    padding: 20px;
    align-items: center;
    justify-content: center;
    background-color: rgb(233, 233, 233);
}
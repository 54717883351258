.sticky-header {
    position: sticky;
    top: 0;
    background-color: #2c2c2c;
    padding: 15px;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
}

.menu-item {
    font-weight: bold;
    text-decoration: none;
    color: white;
}
.page-card {
    display: flex;
    width: 100%;
    min-width: 300px;
    max-width: 600px;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    box-shadow: 5px 5px 3px rgb(192, 192, 192);
    background-color: whitesmoke;
    cursor: pointer;
}

.page-card:hover{
    transform: scale(1.05);
}

.card-title{
    font-weight: normal;
    font-size: 22px;

}

.card-content{
    font-weight: normal;
    font-size: 15px;
}
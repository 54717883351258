.gallery-stripe {
    background-color: rgb(46, 88, 167);
    padding: 10px 10px 20px 10px;
    text-align: center;
    margin-bottom: 0px;
}

.gallery-header1 {
    color: white;
    font-size: 45px;
    font-weight: normal;
    padding: 0px;
    margin-top: 5px;
    margin-bottom: 0px;
}

.gallery-header2 {
    color: black;
    font-size: 35px;
    font-weight: bold;
    padding: 5px;
    margin-bottom: 0px;
    text-align: center;
}

.gallery-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-video {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px
}
.thesis-stripe {
    background-color: rgb(46, 88, 167);
    padding: 10px 10px 20px 10px;
    text-align: center;
    margin-bottom: 0px;
}

.thesis-header1 {
    color: white;
    font-size: 40px;
    font-weight: normal;
    padding: 0px;
    margin-top: 5px;
    margin-bottom: 0px;
}

.thesis-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.thesis-text {
    text-align: left;
    text-indent: 30px;
    font-size: 15px;
    width: 95%;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 10px;
}

.thesis-video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px
}